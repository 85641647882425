import React from 'react';
import "./ErrorPage.css";

const ErrorPage = () => {
    return (
        <div
            className='backgroundError'
            style={{
                width: '100vw', // 100% del ancho de la ventana
                height: '100vh', // 100% del alto de la ventana
                justifyContent: 'center',
                alignItems: 'center',
                // Puedes agregar más estilos según tus necesidades
            }}
        >
            <div id="backgroundError"></div>
            <div class="top">
                <h1 className='errorH1'>404</h1>
                <h3 className='errorH3'>Parece que te has perdido</h3>
            </div>
            <div class="container">
                <div class="ghost-copy">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                    <div class="four"></div>
                </div>
                <div class="ghost">
                    <div class="face">
                        <div class="eye"></div>
                        <div class="eye-right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
                <div class="shadow"></div>
            </div>
            <div class="bottom">
                <h3 className='errorH3'>Recuerda ingresar con tu link único de invitado</h3>
                
            </div>
        </div>
    );
};

export default ErrorPage;