import React from 'react';
import imageTwo from "./assets/imgTwo.png";
import borderImage from "./assets/flores/3.png";


const Novios = () => {
  const containerStyle = {
    display: 'grid',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    margin: '27.5% 5% 25% 5%', // Centra el contenido horizontalmente
  };


  const imageStyle = {
    width: '100%', // Ajusta el tamaño de la imagen según tus necesidades
    height: '100%', // Ajusta el tamaño de la imagen según tus necesidades
    backgroundImage: `url(${imageTwo})`, // Utiliza la importación de la imagen
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%', // Hace que la imagen sea circular
    margin: '50% 0'

  };

  return (
    <div style={containerStyle}>
      <h1 style={{
        flex: 1,
        textAlign: 'center',
        fontSize: '2.6rem',
        margin:'0 0 20% 0'
      }}>Natacha Soto</h1>
      
      <div style={imageStyle}></div>
      {/* <div style={{ // flores del count
        width: '100%',
        height: '75%',
        backgroundImage: `url(${flor3})`,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: 'translate(-5.5%,2.5%)',
        zIndex: '1'
      }} /> */}
      <h1 style={{
        flex: 1,
        textAlign: 'center',
        fontSize: '2.6rem',
        margin:'20% 0 0 0'
      }}>Cristopher Pozas</h1>
    </div>
  );
};

export default Novios;
