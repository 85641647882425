import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import mapStyle from './mapStyle';
//map
const containerStyle = {
  width: '100%',
  height: '35vh',
};


const Map = () => {
  const [zoom] = useState(16);
  const [center, setCenter] = useState({
    lat: -33.6602395,
    lng: -70.8341725,
  });

  const markerId = uuidv4();


  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBG6vtb7SL-ulIbKh7PkrMKNkYk7YPaCFo',
    libraries: ['visualization'],
  });

  return (
    <>
    <Row style={{ margin:'15% 5% 0 5%', backgroundColor:'#324c2f', padding: '1%' }}>
      <h1 style={{color:'white'}}>¿Cómo llegar?</h1>
      <h2 style={{ color:'white'}}>Av. Lonquén Sur 6566, Calera de Tango</h2>

      </Row>

      <Row style={{ marginTop: '5%' }}>
        <Col >
          <>
            {
              isLoaded ?
                (
                  <GoogleMap
                    onClick={() => {
                      // Abre la URL en una nueva ventana o pestaña cuando se hace clic en el botón
                      window.open('https://goo.gl/maps/myB6nthgReXV91TQ8', '_blank');
                    }}
                    mapContainerStyle={containerStyle}
                    fullscreenControl={false}
                    center={center}
                    zoom={zoom}
                    onLoad={() => {
                      window.google.maps.LatLngBounds(center)
                    }}
                    options={{
                      styles: mapStyle,
                      fullscreenControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      zoomControl: true,
                      zoomControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_CENTER,
                      },
                    }}
                  >
                    <MarkerF
                      key={markerId}
                      position={{
                        lat: -33.6602395,
                        lng: -70.8341725,
                      }}
                      animation={window.google.maps.Animation.DROP}
                    />
                  </GoogleMap>

                )
                : (<></>)
            }
          </>
        </Col>

      </Row>
      </>
  );
};

export default Map;
