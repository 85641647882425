import './App.css';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import HomePage from './HomePage';
import ErrorPage from './ErrorPage';

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/error" component={ErrorPage} />
        <Redirect from="*" to="/error" />
      </Switch>
    </Router>
  );
}

export default App;
