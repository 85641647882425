import React from 'react';
import './App.css';
import { Col, Row } from 'reactstrap';
import flor7 from './assets/flores/7.png'

import imageOne from "./assets/1.jpeg";

const Header = () => (

  <Row style={{ position: 'relative', zIndex: '1' }}> {/* Aplicamos posición relativa y z-index */}
        <Col xxs="12" className="text-center" style={{ position: 'relative', overflow: 'hidden' }}>
          <img
            src={imageOne}
            alt="Descripción de la imagen"
            style={{ maxWidth: '100%', width: '100%', height: 'auto', objectFit:'cover' }}
          />
        </Col>
        <div
          style={{
            position: 'absolute',
            bottom: '-45%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '70%',
            height: '59%',
            backgroundColor: '#fff',
            color: 'white',
            textAlign: 'center',
            borderStyle: 'double',
            borderColor: '#172b12'

          }}
        >
          <h1 className='HeaderNovios'>Cristopher & Natacha</h1>
          <h1 className='HeaderDate'>02 - 12 - 2023</h1>

        </div>

        <div style={{ // flor marco arriba
              width: '50%',
              height: '80%',
              backgroundImage: `url(${flor7})`,
              backgroundSize: 'cover',
              position: 'absolute',
              transform: 'translate(100%, -48.5%) rotate(180deg)',
              zIndex: '2000'
            }} />

            <div style={{ // flor marco abajo
              width: '50%',
              height: '80%',
              backgroundImage: `url(${flor7})`,
              backgroundSize: 'cover',
              position: 'absolute',
              transform: 'translate(0,-15%)',
              zIndex: '2000'
            }} />
      </Row>
);

export default Header;
