import './App.css';

import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { Col, Row } from 'reactstrap';

import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore"
import Timeline from './timeline';
import Asistencia from './asistencia';
import Header from './header';
import CartaDeInvitacion from './cartaInvitacion';
import Novios from './novios';
import Map from './map';
import CountdownTimer from './countDown';
import flor8 from './assets/flores/8.png'

const firebaseConfig = {

  apiKey: "AIzaSyBG6vtb7SL-ulIbKh7PkrMKNkYk7YPaCFo",

  authDomain: "invitacionboda-398702.firebaseapp.com",

  projectId: "invitacionboda-398702",

  storageBucket: "invitacionboda-398702.appspot.com",

  messagingSenderId: "331457994385",

  appId: "1:331457994385:web:bbce473b18725580f5a930",

  measurementId: "G-5TX5BCC9C5"

};


const app = initializeApp(firebaseConfig);

const db = getFirestore(app)


//events
const events = [
  {
    title: '19:00',
    text: 'Entrada',
  },
  {
    title: '19:40',
    text: 'Ceremonia',
  },
  {
    title: '21:40',
    text: 'Cena',
  },
  {
    title: '22:00',
    text: 'Torta',
  },
  {
    title: '22:10',
    text: 'Fiesta',
  },
  {
    title: '02:00',
    text: 'Salida',
  },
];

function HomePage() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idFromURL = searchParams.get('id');
  const [redirectToError, setRedirectToError] = useState(false);
  const [invitadoData, setInvitadoData] = useState(null); // Para almacenar los datos del invitado
  const [nombresInvitadosState, setNombresInvitadosState] = useState(null); // Para almacenar los datos del invitado
  let docRef;

  useEffect(() => {
    if (!idFromURL) {
      // El ID está vacío, establece redirectToError a true para redirigir a la página de error.
      setRedirectToError(true);
    } else {
      // El ID no está vacío, intenta buscar el documento en la colección "invitados".
      docRef = doc(db, 'invitados', idFromURL);

      getDoc(docRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            // El documento existe en la base de datos. Puedes acceder a sus datos con docSnap.data().
            const data = docSnap.data();
            console.log('Datos del invitado:', data.integantes);
            setInvitadoData(data.integantes); // Almacena los datos del invitado en el estado.

            // Comprobar si invitadoData no es nulo antes de acceder a 'integrantes'
            const nombresInvitados =
              data && data.integantes
                ? data.integantes.map((invitado) => invitado.nombre).join(' • ')
                : '';

            // Actualizar nombresInvitados en el estado si es diferente
            if (nombresInvitados !== nombresInvitadosState) {
              setNombresInvitadosState(nombresInvitados);
            }
          } else {
            // El documento no existe en la base de datos.
            console.log('El documento no existe');
            setRedirectToError(true);
          }
        })
        .catch((error) => {
          console.error('Error al obtener el documento:', error);
          setRedirectToError(true);
        });
    }

  }, [idFromURL]);

  // Si redirectToError es true, redirige a la página de error.
  if (redirectToError) {
    return <Redirect to="/error" />;
  }


  return (
    <>
      {
        invitadoData !== null ?
          <div className="App">

            <Header />



            <Row style={{ margin: '40% 5%  5%' }}>
              <h2 className='InvitacionPara' >Invitación para:</h2>
              <h1 className='NombresInvitados' style={{ zIndex: '10' }}>{nombresInvitadosState}</h1>
            </Row>



            <CountdownTimer />


            <Novios />

            <Map />

            <Row style={{ marginTop: '15%' }}>
            
              <Col style={{ position:'relative'}}>
              <div style={{ // flores del count
                    width: '30%',
                    height: '100%',
                    backgroundImage: `url(${flor8})`,
                    backgroundSize: 'cover',
                    position: 'absolute',
                    transform: 'translate(233.5%, 0%)',
                    zIndex: '-10',
                    opacity: 0.6
                  }} />
                <div style={{ // flores del count
                    width: '30%',
                    height: '100%',
                    backgroundImage: `url(${flor8})`,
                    backgroundSize: 'cover',
                    position: 'absolute',
                    transform: 'translate(0%, 0%) rotate(180deg)',
                    zIndex: '-10',
                    opacity: 0.6
                  }} />
                <h1 style={{ marginBottom: '3rem' }}>Itinerario</h1>
                  
                
                <Timeline events={events} />


              </Col>

              {/* <div style={{ // flor marco abajo
                  width: '100%',
                  height: '45%',
                  backgroundImage: `url(${flor8})`,
                  backgroundSize: 'cover',
                  position: 'absolute',
                  transform: 'translate(13%,20%) rotate(-90deg)',
                  zIndex: '2000',
                  opacity: 0.6
                }} />
                <div style={{ // flor marco abajo
                  width: '100%',
                  height: '25%',
                  backgroundImage: `url(${flor8})`,
                  backgroundSize: 'cover',
                  position: 'absolute',
                  transform: 'translate(23%,167.75%) rotate(-90deg)',
                  zIndex: '2000',
                  opacity: 0.6
                }} />

                <div style={{ // flor marco abajo
                  width: '100%',
                  height: '25%',
                  backgroundImage: `url(${flor8})`,
                  backgroundSize: 'cover',
                  position: 'absolute',
                  transform: 'translate(-23%,167.75%) rotate(90deg)',
                  zIndex: '2000',
                  opacity: 0.6
                }} />
                <div style={{ // flor marco abajo
                  width: '100%',
                  height: '25%',
                  backgroundImage: `url(${flor8})`,
                  backgroundSize: 'cover',
                  position: 'absolute',
                  transform: 'translate(-23%,20%) rotate(90deg)',
                  zIndex: '2000',
                  opacity: 0.6
                }} /> */}
            </Row>

            <CartaDeInvitacion />



            <Asistencia db={db} idFromURL={idFromURL} invitados={invitadoData} />


          </div > :
          //LOADING
          <div
            style={{
              width: '100vw', // 100% del ancho de la ventana
              height: '100vh', // 100% del alto de la ventana
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // Puedes agregar más estilos según tus necesidades
            }}
          >
            <div class="dot-wave">
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
              <div class="dot-wave__dot"></div>
            </div>
          </div>
      }
    </>
  );
}

export default HomePage;
