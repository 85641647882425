import React, { useState } from 'react';
import './App.css';
import { Row } from 'reactstrap';
import { doc, updateDoc } from "firebase/firestore"
import Notification from './Notification';
import flor10 from './assets/flores/10.png'


const Asistencia = ({ db, idFromURL, invitados }) => {
  console.log('🎖️', invitados);
  const [showNotification, setShowNotification] = useState(false); // Estado para mostrar u ocultar la notificación
  const [asistencias, setAsistencias] = useState(
    invitados.map((invitado) => ({
      nombre: invitado.nombre,
      asistencia: invitado.asistencia,
      menu: invitado.menu || 'menu tradicional',
      infantil: invitado.infantil,
      editable: invitado.editable ?? false
    }))
  );

  const handleNombreChange = (index, nuevoNombre) => {
    const updatedAsistencias = [...asistencias];
    updatedAsistencias[index].nombre = nuevoNombre;
    setAsistencias(updatedAsistencias);
  };


  const handleAsistenciaChange = (index) => (e) => {
    const updatedAsistencias = [...asistencias];
    updatedAsistencias[index].asistencia = e.target.checked;
    setAsistencias(updatedAsistencias);
  };

  const handleMenuChange = (index) => (e) => {
    const updatedAsistencias = [...asistencias];
    updatedAsistencias[index].menu = e.target.value;
    setAsistencias(updatedAsistencias);
  };



  const handleCloseNotification = () => {
    // Oculta la notificación cuando se hace clic en "Cerrar"
    setShowNotification(false);
  };

  const updateFirestore = async () => {
    try {
      const docRef = doc(db, 'invitados', idFromURL);
      await updateDoc(docRef, { integantes: asistencias }); // Actualiza el documento con los nuevos datos de invitados.
      console.log('Documento actualizado en Firestore:', asistencias);
      setShowNotification(true);
    } catch (error) {
      console.error('Error al actualizar el documento en Firestore:', error);
      setShowNotification(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFirestore(); // Llama a la función para actualizar Firestore.
  };

  return (
    <div style={{ backgroundColor: '#98aa94', width: '100%', height: 'auto', marginBottom: '0%', display: 'grid',position: 'relative', }}>
      {showNotification && (
        <Notification
          message="Los cambios se han guardado correctamente."
          onClose={handleCloseNotification}
        />
      )}
      <div style={{ // flor marco arriba
        width: '70%',
        paddingBottom:'15%',
        backgroundImage: `url(${flor10})`,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: 'translate(20%, 0%) rotate(0deg)',
        opacity: 0.9,
        zIndex: '1'
      }} />
      <Row style={{ margin: '10% 5% 0 5%', zIndex:'10' }}>
        <h1 style={{ color: '#fff' }}>Selecciona la asistencia y el tipo de menú para cada integrante</h1>

      </Row>
      <form onSubmit={handleSubmit} style={{ backgroundColor: '#fff', margin: '5% 5% 5% 5%', display: 'inline-block', borderRadius: '8px' }}>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5%' }}>
          <h1 style={{ flex: '2', fontSize: '1.2rem' }}>
            Invitado
          </h1>
          <h1 style={{ flex: '1', fontSize: '1.2rem' }}>
            Asistencia
          </h1>
          <h1 style={{ flex: '2', fontSize: '1.2rem' }}>
            Menú
          </h1>
        </div>

        {asistencias.map((invitado, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5%' }}>
            {invitado.editable ? (
              <h2
                contentEditable={true}
                style={{
                  flex: '2',
                  marginRight: '10px',
                  fontSize: '0.9rem',
                  border: '0.125rem solid #172b12',
                  borderRadius: '0.25rem',
                  outline: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onBlur={(e) => {
                  let trimmedText = e.target.textContent.trim().substring(0, 15);
                  if (trimmedText === '') {
                    trimmedText = 'Acompañante';
                  }
                  const lettersOnly = trimmedText.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]+/g, '');
                  e.target.textContent = lettersOnly;
                  handleNombreChange(index, lettersOnly);
                }}
                onClick={(e) => {
                  if (e.target.textContent.trim() === 'Acompañante') {
                    e.target.textContent = '';
                  }
                }}
                maxLength={15}
              >
                {invitado.nombre}
              </h2>

            ) : (
              <h2 style={{
                flex: '2',
                marginRight: '10px',
                fontSize: '0.9rem',
                whiteSpace: 'nowrap', // Evitar que el texto se divida en varias líneas
                overflow: 'hidden',
                textOverflow: 'ellipsis', // Agregar puntos suspensivos si el texto es demasiado largo
              }}>
                {invitado.nombre}
              </h2>
            )}
            <div style={{ flex: '1' }}>
              <input
                type="checkbox"
                checked={invitado.asistencia}
                onChange={handleAsistenciaChange(index)}
              />
            </div>
            <div class="select" style={{ flex: '2' }}>
              <select
                value={invitado.menu}
                onChange={handleMenuChange(index)}
              >
                <option value="Menú tradicional">Tradicional</option>
                <option value="Menú vegetariano">Vegetariano</option>
                {invitado.infantil || invitado.editable ? (
                  <option value="Menú infantil">Infantil</option>
                ) : (<></>)}
              </select>
            </div>
          </div>
        ))}

        <div style={{ marginBottom: '10%' }}>
          <input type="submit" value="Confirmar" />
        </div>
      </form>
      <h2 style={{ fontStyle: 'italic', fontSize: '0.9rem', marginBottom: '10%', color: 'white' }} >¡Esperamos ansiosos celebrar con todos ustedes!</h2>


    </div>
  );
};

export default Asistencia;
