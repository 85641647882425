import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import flor9 from './assets/flores/9.png'

import './CountdownTimer.css'; // Importa tu archivo CSS

const CountdownTimer = () => {
  // Fecha objetivo (2 de diciembre de 2023 a las 20:00 hora chilena)
  const targetDate = moment.tz('2023-12-02 20:00', 'America/Santiago');

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  function calculateTimeLeft(targetDate) {
    const now = moment.tz('America/Santiago');
    const diff = targetDate.diff(now);

    const duration = moment.duration(diff);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <div style={{ position: 'relative', display: 'flow-root' }}>

      <div className="countdown-container">
        <div className="countdown-column">
          <h1 style={{ color: '#fff', marginBlockEnd: '0.1rem' }}>{timeLeft.days}</h1>
          <h2 style={{ fontSize: '1rem', color: '#fff' }}>Días</h2>
        </div>
        <div className="countdown-column">
          <h1 style={{ color: '#fff', marginBlockEnd: '0.1rem' }}>{timeLeft.hours}</h1>
          <h2 style={{ fontSize: '1rem', color: '#fff' }}>Hrs</h2>
        </div>
        <div className="countdown-column">
          <h1 style={{ color: '#fff', marginBlockEnd: '0.1rem' }}>{timeLeft.minutes}</h1>
          <h2 style={{ fontSize: '1rem', color: '#fff' }}>Min</h2>
        </div>
        <div className="countdown-column">
          <h1 style={{ color: '#fff', marginBlockEnd: '0.1rem' }}>{timeLeft.seconds}</h1>
          <h2 style={{ fontSize: '1rem', color: '#fff' }}>Seg</h2>
        </div>

      </div>
      <div style={{ // flores del count
        width: '100%',
        height: '100%',
        backgroundImage: `url(${flor9})`,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: 'translate(0,-118.5%)',
        zIndex: '-10'
      }} />

      <div style={{ // flores del count
        width: '100%',
        height: '100%',
        backgroundImage: `url(${flor9})`,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: 'translate(0,-55.5%) rotate(180deg)',
        zIndex: '-10'
      }} />

    </div>
  );
};

export default CountdownTimer;
