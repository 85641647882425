import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './timeline.css';

// const TimelineEvent = ({ title, text, alignLeft }) => (
//   <div className={`timeline-event ${alignLeft ? 'left' : 'right'}`}>
//     <div className="timeline-content">
//       <h2 className="event-title">{title}</h2>
//       <p className="event-text">{text}</p>
//     </div>
//     <div className="timeline-circle">
//       <FontAwesomeIcon icon={faCircle} />
//     </div>
//   </div>
// );

const Timeline = ({ events }) => (
  

  <ul>
    {events.map((event, index) => (
      <li key={index} style={{accentColor:'#41516C'}}>
        <h1 class={`date ${event.text}`}>{event.title}</h1>
        <h2 class="title" >{event.text}</h2>
      </li>
    ))}

  </ul>
);

export default Timeline;
