import React, { useState } from 'react';

const CartaDeInvitacion = () => {
  const [mostrarInfoBancaria, setMostrarInfoBancaria] = useState(false);

  const toggleInfoBancaria = () => {
    setMostrarInfoBancaria(!mostrarInfoBancaria);
  };

  return (
    <div style={{ margin:'25% 5% 15% 5%', fontSize: '0.6rem', lineHeight: '1.5', textAlign: 'left' }}>
      <h1 style={{ margin:'0 5% 10% 5%', fontSize:'2.5rem', textAlign:'center' }}>Regalo</h1>

      <h2>Queridos amigos y familiares,</h2>

      <h2>Nos sentimos muy emocionados de compartir este día especial con todos ustedes. Lo más importante para nosotros es tenerlos a nuestro lado mientras celebramos el comienzo de nuestra vida juntos.</h2>

      <h2>En lugar de regalos materiales, preferimos recibir donativos en efectivo para ayudarnos a comenzar esta nueva etapa de nuestras vidas. Entendemos que cada uno tiene su propia situación financiera, pero para facilitar la organización y la planificación de nuestra boda, les informamos que hemos establecido un monto mínimo de donación de 30.000 pesos por persona.</h2>
      
      <h2>Además, agradecemos su comprensión y apoyo en este momento tan importante para nosotros. Para garantizar una adecuada preparación, les pedimos que confirmen su asistencia antes del 2 de noviembre de 2023.</h2>

      <br/>

      
      <div style={{ textAlign: 'center' }}>
      <button onClick={toggleInfoBancaria} style={{ backgroundColor: '#172b12', border: 'none', cursor: 'pointer', padding:'1rem', color:'#fff', borderRadius:'4px' }}>
        <i class="fa-solid fa-gift" style={{marginRight:'0.5rem'}}></i>
         Enviar Regalo
      </button>
      </div>
      
      {mostrarInfoBancaria && (
        <div>
          <br/>
          <h2>Pueden hacer la entrega del regalo el día del evento o efectuar una transferencia bancaria como método de entrega.</h2>
          <h2>Les proporcionamos los detalles de nuestra cuenta bancaria para transferencias a continuación:</h2>
          <br/>
          <h2>Nombre: Natacha Soto Segovia</h2>
          <h2>Rut: 20.986.806-7</h2>
          <h2>Tipo de cuenta: Corriente</h2>
          <h2>Número de Cuenta: 0 000 80 76925 3</h2>
          <h2>Nombre del Banco: Santander</h2>
          <h2>Correo: snatacha1403@gmail.com</h2>
        </div>
      )}
      <br/>
      <h2 style={{fontStyle:'italic', fontSize:'0.8rem'}} >Su generosidad será apreciada de corazón y nos ayudará a construir un futuro juntos. ¡Esperamos ansiosos celebrar con todos ustedes!</h2>

    </div>
  );
};

export default CartaDeInvitacion;
